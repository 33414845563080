import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import { prepareParagraph, srcSetProps, urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ products }) => {
  const sliders = products.products?.length || 0

  const sliderSettings = {
    spaceBetween: 32,
    slidesPerView: 3.2,
    simulateTouch: true,
    className: styles.slider,
    breakpoints: {
      0: {
        slidesPerView: 1.3,
        spaceBetween: 16,
      },
      700: {
        slidesPerView: sliders,
      },
    },
  }

  return (
    <div
      className={cx(styles.products, {
        [styles.productsMany]: sliders > 2,
      })}
    >
      <div className={styles.wrap}>
        <div className={cx(styles.col, styles.col1)}>
          <h2 className={styles.title}>{products.title}</h2>
          <p className={styles.description}>{prepareParagraph(products.description)}</p>
        </div>
        <div className={cx(styles.col, styles.col2)}>
          <Swiper {...sliderSettings}>
            {products.products &&
              products.products.map(product => (
                <SwiperSlide>
                  <div className={styles.card}>
                    <div className={styles.content}>
                      <div className={styles.contentTop}>
                        <p className={styles.name}>{product.name}</p>
                        <p className={styles.motto}>
                          <span>{product.motto}</span>
                        </p>
                        <img
                          className={styles.image}
                          {...srcSetProps(sanityImageUrl(product.image))}
                          alt={product.image?.caption}
                        />
                        <p className={styles.desc}>{product.description}</p>
                      </div>
                      <div className={styles.links}>
                        {product.isDisabled ? (
                          <a
                            aria-label={product.getStartedButtonAreaLabel}
                            className={cx(styles.blue, styles.blueDisabled)}
                          >
                            {product.getStartedButtonText}
                          </a>
                        ) : (
                          <a
                            aria-label={product.getStartedButtonAreaLabel}
                            href={product.getStartedButtonUrl}
                            onClick={urlWithSearchParamsHandler}
                            className={styles.blue}
                          >
                            {product.getStartedButtonText}
                          </a>
                        )}

                        <Link
                          aria-label={product.learnMoreButtonAreaLabel}
                          className={styles.white}
                          to={product.learnMoreButtonUrl}
                        >
                          {product.learnMoreButtonText}
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
